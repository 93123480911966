.withImageProductCard {
  border-radius: 16px;
  height: 214px;
  width: 177px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.noTraywithBannerProductCard {
  border-radius: 16px;
  height: 256px;
  width: 190px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.withImageProductCard::before {
  background: var(--primary-shade) !important;
}

.noTraywithBannerProductCard::before {
  background: var(--primary-shade) !important;
}

.withImageProductCardGrey::before {
  background: var(--primary-shade) !important;
}

.withImageProductCardBlue h4 {
  color: var(--font-color) !important;
}

.imageProductCardDubai {
  border-radius: 16px;
  height: 272px;
  width: 284px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.withImageProductCardDubai {
  border-radius: 16px;
  height: 380px;
  width: 296px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.withImageProductCardDubai::before {
  background: var(--primary-shade) !important;
}

.new-ui-added-to-cart::before {
  background: var(--primary-color) !important;
}

.new-ui-added-to-cartNoTrayWithBanner::before {
  background: var(--primary-color) !important;
}

.new-ui-item-pos {
  position: absolute;
  left: 0%;
  right: 68.97%;
  top: 0%;
  bottom: 86.49%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(145, 158, 171, 0.3), 0px 12px 24px -4px rgba(145, 158, 171, 0.2);
  border-radius: 16px 0px;
  z-index: 1;
}

.new-ui-item-pos-dubai {
  position: absolute;
  left: 0%;
  right: 68.97%;
  top: 2%;
  bottom: 86.49%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(145, 158, 171, 0.3), 0px 12px 24px -4px rgba(145, 158, 171, 0.2);
  border-radius: 16px 0px;
  z-index: 1;
}

.sold-out-product-parent::before {
  background: var(--disabled-color) !important;
}

.sold-out-product-parent:active {
  transform: none !important;
}

.sold-out-product-parent:active::before {
  transform: translate3d(0, 0.75em, -1em) !important;
}

.sold-out-product {
  filter: grayscale(100%);
}

.sold-out-product-img {
  position: absolute;
  top: 0;
  right: 0;
}

.withImageProductCardImage {
  height: 120px;
  width: 160px;
  object-fit: contain;
  padding: 0px 20px;
}

.no-product-img-parent {
  height: 120px;
}

.no-product-img-parent-dubai {
  width: 284px;
  height: 272px;
}

.no-product-img {
  max-height: 100px;
  width: 200px;
  object-fit: contain;
}

.new-ui-empty-card {
  color: var(--font-color);
}

.new-uicard-body {
  padding-top: 12px;
  height: 100%;
}

.item-pos-display {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-ui-selected-product-card {
  color: #d80000;
}

.new-ui-unselected-product-card {
  color: #d80000;
}

.selected-card-blue::before {
  background-color: var(--primary-color) !important;
}

.new-ui-product-position {
  font-weight: bold;
}

.new-ui-product-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.new-ui-product-name {
  margin: 0;
  color: var(--font-color);
  max-width: 150px;
  padding: 5px 0px;
}

.new-ui-product-price-quantity {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 10px;
}

.new-ui-product-price-quantity-noTraywithbanner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: fixed;
  height: 42px;
  bottom: 20px;
  left: 0px;
  right: 0px;
  /* padding-top: 10px; */
  padding-bottom: 4px;
  border-radius: 0px 0px 16px 16px;
  box-sizing: border-box;
  /* border: 1px solid red; */
  /* background-color: #d30c0c !important; */
}

.price-hidden {
  justify-content: center;
}

.new-ui-product-price h4 {
  font-weight: bold;
  margin-bottom: 0;
  color: var(--font-color);
  width: 25%;
}

.new-ui-cart-row-no-tray-with-banner {
  /* background-color: #D80000; */
  /* background-color: #161616; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 65%;
  border-radius: 16px;
  height: 36px;
  border-radius: 8px;
}

.new-ui-cart-row {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 65%;
  border-radius: 16px;
  height: 36px;
  border-radius: 8px;
}

.new-ui-cart-row-added {
  background-color: var(--primary-color);
}

/* .new-ui-cart-row-added-emailotp {
  background-color: #161616;
} */

.edit-cart-quantity {
  width: 30%;
}