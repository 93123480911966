.vending-item-card {
  padding: 20px;
  margin: 20px auto;
}

.vending-item-card-image {
  border: 1px solid #fff;
}

.vend-success {
  background: $color-green;
  color: #fff;

  ion-icon {
    color: #fff;
    background: $color-green;
  }
}

.vend-failed {
  background: $color-red;
  color: #fff;

  ion-icon {
    color: #fff;
    background: $color-red;
  }
}

.vend-loading {
  background: #777;
  color: #fff;

  span {
    ion-icon {
      background: #fff;
    }
  }
}

.vending-item {
  background: $color-yellow;
  color: #fff;

  span {
    ion-icon {
      background: #fff;
    }
  }
}

.vend-item-new {
  border: 2px solid var(--colorShadeA);
  border-radius: 16px;
  background: var(--base-color);
  transform-style: preserve-3d;
  transition: all 175ms cubic-bezier(0, 0, 1, 1) !important;
  margin-bottom: 30px;
  font-weight: bold;
  transition-delay: 0s;
  border: 3px solid var(--wendor-grey-300);
}

.vend-item-new::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary-shade);
  border-radius: inherit;
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.35em 0 0 var(--colorShadeA);
  transform: translate3d(0, 0.75em, -1em);
  // transition: all 175ms cubic-bezier(0, 0, 1, 1);
  transition: all 150ms cubic-bezier(0, 0, 1, 1);
}

.vend-success-new::before {
  background: $color-green;
}

.vend-failed-new::before {
  background: $color-red;
}

.vend-loading-new::before {
  background: #777;
}

.vending-item-new::before {
  background: $color-yellow;
}
