/* .screensaver-container {
  display: flex;
  background-color: #000;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.screensaver {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 9;
  height: 100%;
  width: 100%;
}
.leave-screensaver {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
} */

/* .screensaver-button {

  height: 100%;
  font-size: 36px;
  letter-spacing: 1px;
  word-spacing: 5px;
} */

/* MyComponent.css */
.fullscreen-container {
  height: 100vh; /* Set the container height to occupy the full viewport height */
  display: flex; /* Use flexbox to center the image vertically */
  justify-content: center;
  align-items: center;
}

.vertical-image {
  border: 1px solid black;
  max-height: 100vh; /* Set the maximum image height to the full viewport height */
  max-width: 100%; /* Allow the image to adjust its width responsively */
}

.screensaver-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.screensaver {
  width: 100%; /* Make the image fill the container horizontally */
  height: 100%; /* Make the image fill the container vertically */
  object-fit: cover;
}
.leave-screensaver {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
}

.screensaver-button {
  /* padding: 30px 20px; */
  height: 100%;
  font-size: 36px;
  letter-spacing: 1px;
  word-spacing: 5px;
}
