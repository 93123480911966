.blue-background {
  height: 200px;
  background: linear-gradient(270deg, rgba(25, 48, 255, 0.2) 0%, rgba(1, 129, 143, 0) 100%), #01818f;
}

.active-screen {
  display: block;
}

.UI-is-idle .active-screen {
  display: none;
}

.selection-page-container .idle-screen {
  height: 100%;
  width: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #000;
}

.products-separator {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}

.products-separator::before,
.products-separator::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid #637381;
}

.products-separator:not(:empty)::before {
  margin-right: 0.25em;
}

.products-separator:not(:empty)::after {
  margin-left: 0.25em;
}

.products-separator h2 {
  color: #637381;
  margin-bottom: 0;
  font-weight: bold;
}

.user-is-working .idle-screen {
  display: none;
}

.add-video {
  position: relative;
  top: 0;
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  left: 0;
}

.home-screen-container {
  /* position: relative; */
  height: 100vh;
}

.idle-screen {
  position: absolute;
  top: 0%;
  left: 0%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100%;
  background-color: #000;
}

.swipe-finger {
  position: absolute;
  top: 200px;
  left: 100px;
}

.swipe-finger img {
  width: 200px;
  height: 200px;
  opacity: 0;
  position: relative;
  /* animation: imageTransition 12s infinite; */
}

.swipe-finger p {
  position: absolute;
  top: -20px;
  left: 0;
  width: 0ch;
  height: 24px;
  color: #fff;
  /* animation: textTransition 12s infinite; */
  background-color: #000;
  overflow: hidden;
  font-size: 24px;
  letter-spacing: 1px;
  word-spacing: 5px;
}

.screeen-saver-button {
  flex: 1;
  position: absolute;
  bottom: 10px;
  z-index: 9999;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.screeen-saver-button h2 {
  padding: 20px 100px;
  border-radius: 25px;
  background-color: #02d55d;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 5px;
  font-size: 40px;
}

.atlaswithlocker-animation {
  height: 100%;
  display: flex;
  align-items: center;
}

.atlaswithlocker-animation div:nth-child(1) {
  height: 100%;
  width: 30%;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
}

.atlaswithlocker-animation div:nth-child(2) {
  height: 100%;
  width: 70%;
  transition: transform 0.3s ease;
  transform: translateX(100%);
}

.home-position {
  transform: translateX(0%) !important;
}

.atlaswithlocker-animation div img {
  height: 100%;
  display: block;
}

.atlaswithlocker-step-info {
  display: flex;
  width: 100%;
  align-items: center;
}

.atlaswithlocker-step-info span:nth-child(1) {
  font-weight: bold;
  font-size: 50px;
  color: white;
  margin-right: 30px;
}

.atlaswithlocker-step-info span:nth-child(2) {
  font-weight: normal;
  font-size: 24px;
  color: white;
}

@keyframes atlaswithlocker-image {
  0% {
    transform: translateX(-100%);
  }

  25% {
    transform: translateX(-75%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes atlaswithlocker-text {
  0% {
    transform: translateX(1000px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes imageTransition {
  /* 0% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50%,
  100% {
    transform: translate(140%, 0%);
    opacity: 0;
  } */
}

@keyframes textTransition {
  /* 0% {
    width: 0;
  }
  40% {
    width: 30ch;
  }
  45%,
  100% {
    width: 0ch;
  } */
}
