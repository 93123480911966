.simple-keyboard {
  height: 385px;
  background-color: #fff !important;
  font-size: 25px !important;
}

.small-keys-email-otp .simple-keyboard .hg-row .hg-button {
  height: 65px !important;
  width: 40px;
  background-color: #dfe3e8 !important;
  margin: 3px;
}

.small-keys-email-otp .hg-row .hg-button {
  height: 65px !important;
  width: 40px;
  background-color: #dfe3e8 !important;
  margin: 3px;
}
