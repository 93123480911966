.btn-payments {
  width: 65px !important;
  height: 40px !important;
}

.btn-payments-dubai-cash {
  width: 85px !important;
  height: 78px !important;
}

.btn-payments-fonepay {
  width: 65px !important;
  height: auto;
}

.wendor-bg-red {
  background-color: var(--primary-color) !important;
  color: white;
}

.wendor-button-block {
  width: 100%;
}

.wendor-bg-green {
  background-color: $color-green !important;
  color: white;
}

.full-length-btn {
  width: 80%;
  height: 60px;
  padding: 0px !important;
  margin: 10%;
  margin-top: 0%;
  border-radius: 0;
  font-size: 24px;
  border-width: 0;
}

.rowproduct-btn-light {
  background-color: lightgray;
  color: #000;
}

.rowproduct-btn-dark {
  background-color: rgb(221, 58, 85);
}

.wendor-bg-grey {
  background-color: $color-grey !important;
  color: white;
}

.selected-payment-method {
  // border: 4px solid #28a745 !important;
  border-radius: 10px;
  width: 100px;
}

/*--------New Buttons-------*/

.new-3d-button {
  border: 2px solid var(--colorShadeA);
  border-radius: 16px;
  background: var(--base-color);
  transform-style: preserve-3d;
  transition: all 175ms cubic-bezier(0, 0, 1, 1) !important;
  margin-bottom: 30px;
  transition-delay: 0s;
}

.new-3d-button::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--disabled-color);
  border-radius: inherit;
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.35em 0 0 var(--colorShadeA);
  transform: translate3d(0, 0.75em, -1em);
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
  // transition: all 150ms cubic-bezier(0, 0, 1, 1);
}

.new-3d-button:active {
  // transform: translate(0em, 0.75em);
  background-color: white;
  color: #333 !important;
}

.new-3d-button:active::before {
  // transform: translate3d(0, 0, -1em);
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}

.new-3d-button-v2 {
  border: 2px solid var(--colorShadeA);
  border-radius: 16px;
  background: var(--base-color);
  transform-style: preserve-3d;
  transition: all 175ms cubic-bezier(0, 0, 1, 1) !important;
  transition-delay: 0s;
}

.new-3d-button-v2::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--disabled-color);
  border-radius: inherit;
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.35em 0 0 var(--colorShadeA);
  transform: translate3d(0, 0.75em, -1em);
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
  filter: drop-shadow(0px 4px 24px rgba(145, 158, 171, 0.12)) drop-shadow(0px 0px 2px rgba(145, 158, 171, 0.2));
  // transition: all 150ms cubic-bezier(0, 0, 1, 1);
}

.new-3d-button-v2:active {
  transform: translate(0em, 0.75em);
  background-color: white;
  color: #333 !important;
}

.new-3d-button-v2:active::before {
  transform: translate3d(0, 0, -1em);
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}

.touch-animation {
  transform: translate(0em, 0.75em) !important;
  // background-color: white !important;
  color: var(--font-color);
}

.touch-animation::before {
  transform: translate3d(0, 0, -1em) !important;
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}

.touch-animation-small {
  transform: translate(0em, 0.45em) !important;
  color: white;
}

.touch-animation-small::before {
  transform: translate3d(0, 0, -1em) !important;
  background: var(--primary-color) !important;
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}

.payment-method-button {
  width: 140px;
  height: 108px;
  color: #333 !important;
}

.payment-method-button:hover {
  background-color: white !important;
  color: #333 !important;
}

.payment-method-button-active {
  background-color: #fff !important;
  color: #333 !important;
  border: 1px solid var(--primary-color) !important;
  box-shadow: none;
}

.payment-method-button-active::before {
  background: var(--primary-color) !important;
}

.new-green-submit-btn {
  opacity: 1 !important;
  background: #36b37e !important;
  width: 80%;
}

.new-green-submit-btn:active {
  color: #fff !important;
  transform: translate(0em, 0.45em);
}

.new-green-submit-btn::before {
  background: var(--wendor-light-green) !important;
  transform: translate3d(0, 0.45em, -1em);
}

.new-red-submit-btn {
  opacity: 1 !important;
  background: $primary;
  width: 80%;
}

.new-red-submit-btn:active {
  background: $primary;
  color: #fff !important;
  transform: translate(0em, 0.45em);
}

.new-red-submit-btn::before {
  background: var(--primary-shade) !important;
  transform: translate3d(0, 0.45em, -1em);
}

.new-send-otp-btn {
  padding: 10px 30px !important;
  font-weight: 800;
  border-radius: 8px !important;
  background: var(--wendor-green) !important;
  width: 245px !important;
  margin-top: 12px;
}

.new-send-otp-btn::before {
  background: var(--wendor-green-300) !important;
}

.new-otp-submit-btn {
  width: 245px;
  padding: 10px 30px !important;
  font-weight: 800;
  border-radius: 8px !important;
  background: var(--wendor-green) !important;
  margin-top: 12px;
}

.new-otp-submit-btn::before {
  background: var(--wendor-green-300) !important;
  transform: translate3d(0, 0.45em, -1em);
}

.new-otp-submit-btn:active {
  color: #fff !important;
  transform: translate(0em, 0.45em);
}

.reset-btn {
  border-radius: 8px !important;
  padding: 15px 50px !important;
  font-weight: 800;
  background: white !important;
  color: var(--primary-color) !important;
}

.reset-btn:active {
  color: #d80000 !important;
}

.reset-btn-v2 {
  width: 96px;
  height: 32px;
  border-radius: 16px !important;
  background: white !important;
  color: $primary !important;
  margin-right: 74px;
  margin-left: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.reset-btn-v2:active {
  color: #d80000 !important;
}

.pay-btn-v2 {
  width: 110px;
  height: 32px;
  border-radius: 16px !important;
  background: $primary !important;
  margin-right: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.pay-btn-v2::before {
  background: var(--primary-shade) !important;
}

.pay-btn {
  border-radius: 8px !important;
  padding: 15px 60px !important;
  font-weight: 800;
  background: var(--primary-color) !important;
}

.pay-btn::before {
  background: var(--primary-shade) !important;
}

.cancel-btn {
  border-radius: 8px !important;
  padding: 15px 20px !important;
  font-weight: 800;
}

.cancel-btn-2 {
  border-radius: 8px !important;
  padding: 15px 20px !important;
  font-weight: 800;
  background: white !important;
  // color: $primary !important;
  color: var(--primary-color) !important;
}

.cancel-btn-2:active {
  color: #d80000 !important;
}

/*--------New Buttons css end here-------*/

.selected-payment-method span {
  color: black;
}

.wendor-big-button {
  border-radius: 50px !important;
  padding: 15px 60px !important;
  font-weight: 800;
}

.wendor-small-button {
  margin: 12px 0 12px 0;
  border-radius: 40px !important;
  padding: 10px 30px !important;
  font-weight: 800;
}

/* Ripple effect */
.btn {
  background-position: center;
  transition: background 0.8s;
}

.wendor-bg-green > .btn:hover {
  background: #000 radial-gradient(circle, transparent 1%, rgba(76, 158, 22, 0.486) 1%) center/15000% !important;
}

// .btn:hover {
//   background: #000 radial-gradient(circle, transparent 1%, rgba(114, 26, 26, 0.486) 1%) center/15000%;
// }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 90%;
  }
}

.btn:active {
  background-color: rgba(170, 170, 170, 0.178);
  background-size: 100%;
  transition: background 0s;
}

/* Button style */
button {
  border: none;
  border-radius: 2px;
  padding: 12px 18px;
  font-size: 16px;
  cursor: pointer;
  color: white;
  background-color: rgba(255, 255, 255, 0.486);
  box-shadow: 0 0 4px #999;
  outline: none;
}

.wendor-flat-button-full {
  padding: 10px 30px !important;
  font-weight: 800;
  width: 100%;
}

.default {
  line-height: 2.5em;
}
