.text-green {
  color: $color-green !important;
}

.text-red {
  color: $color-red !important;
}

.payment-cart-title {
  padding-bottom: 20px;
}

.txtCashInitSuccessSubInfo {
  text-align: left;
  color: var(--font-color);
}

.cartDataCards {
  overflow-x: scroll;
}

.cashCardTotalParent {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cashCardTotal {
  border: 2px solid var(--colorShadeA);
  border-radius: 16px;
  background: var(--base-color);
  transform-style: preserve-3d;
  transition: all 175ms cubic-bezier(0, 0, 1, 1) !important;
  margin-bottom: 30px;
  transition-delay: 0s;
  height: 100px;
  border: 2px solid $primary;
  padding: 10px;
}

.cashCardTotal::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $primary;
  border-radius: inherit;
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.35em 0 0 var(--colorShadeA);
  transform: translate3d(0, 0.75em, -1em);
  // transition: all 175ms cubic-bezier(0, 0, 1, 1);
  transition: all 150ms cubic-bezier(0, 0, 1, 1);
}
