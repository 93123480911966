:root {
  --backgroundColor: rgba(246, 241, 209);
  // --colorShadeC: #c4cdd5;
  // --primary-color: #d80000;
  // --colorShadeE: rgb(255, 255, 255);
  // --primary-color: #d20000;
  // --primary-shade: rgba(216, 0, 0, 0.3);
  --wendor-light-green: rgba(54, 179, 127, 0.322);
  --wendor-grey-300: #dfe3e8;
  // --disabled-color: #c4cdd5;
  --wendor-green: #36b37e;
  --wendor-green-300: #86e8ab;
  --wendor-zanskar-blue: #01818f;
  // --wendor-light-grey: rgba(22, 22, 22, 0.3);
  --primary-color: #d80000;
  --base-color: rgb(255, 255, 255);
  --font-family: 'sans-serif';
  --primary-shade: #d800004d;
  --font-color: var(--font-color);
  --disabled-color: #c4cdd5;
  --kiosk-zoom-level: 1;
}

body {
  font-family: sans-serif;
  zoom: var(--kiosk-zoom-level);
  // background: #999 url('/assets/background.jpg') no-repeat fixed 50%;
  background-size: cover;
  background-color: #fff;
  height: 100%;
  overflow: hidden;

  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}

input:focus {
  background-color: yellow !important;
}

.modal-header {
  button.close {
    font-size: 25px !important;
  }
}

.modal-content {
  margin-top: 20px;
  padding: 10px 0;
}

.selectable-input {
  font-size: 24px;
  padding: 4px;
}

.d-webkit-inline-flex {
  display: -webkit-box;
}

::-webkit-scrollbar {
  display: none;
}

.show-scrollbar::-webkit-scrollbar {
  display: initial;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(255, 255, 255, 1);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.width-100-percent {
  width: 100% !important;
}

.width-100-px {
  width: 150px !important;
}

.btn-sm {
  padding: 2px 5px;
}

.pointer {
  cursor: pointer;
}

.form-control {
  color: #000;
  font-size: 20px;
}

.form-control::placeholder {
  color: #000;
  font-size: 20px;
}

img.ui.image {
  // make payment method images in center
  display: initial !important;
}

.ui.dimmer {
  height: 100vh !important;
}

.min-height-80 {
  min-height: 80px !important;
}

.ui.message .header {
  font-size: 25px !important;
}

.ui.message p {
  font-size: 20px !important;
}

.video-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-flex {
  display: flex;
}

.display-inline-block {
  display: inline-block;
}

.text-align-center {
  text-align: center;
}

.full-width {
  width: 100px;
  // div{
  //   width: 200px;
  //   background-color: #fff;
  // }
}

.products-list-rowproductselect {
  display: flex;
  flex-wrap: wrap;
  height: 74vh;
  justify-content: center;
  overflow: scroll;
}

.soldout-ui {
  height: 60px;
  width: 100%;
  display: flex;
  padding: 5px 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #bbb;
}

.soldout-ui p {
  font-size: 35px;
  justify-content: center;
  align-self: center;
  padding: 10px;
  color: #fff;
}

.image-display-rowproduct-select img {
  height: 170px;
  width: auto;
}

.image-display-rowproduct-select-conatainer {
  position: relative;
}

.image-display-rowproduct-select {
  position: absolute;
  right: -12%;
  top: 70px;
}

.product-card-type-row-select {
  height: 300px;
  width: 240px;
  flex-grow: 1;
  color: #fff !important;
  font-weight: bold !important;
  margin-right: 25px;
  background-image: linear-gradient(rgb(231, 144, 163), rgb(196, 87, 44));
  border-radius: 25px;
}

.item-info-rowproduct-select {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 150px;
  padding: 10px;
  padding-top: 10px;
  text-align: left;
}

.animate-product-box-rowitemselect {
  opacity: 1;
  overflow: hidden;
  margin: 15px;
  animation-name: appear;
  animation-duration: 2s;
}

@keyframes appear {
  0% {
    transform: translate(0px, 100px);
    transform: scaleX(0);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0px);
    opacity: 1;
    transform: scaleX(1);
  }
}

.item-quantity-select {
  display: flex;
  font-size: 24px;
  margin-top: 50px;
  z-index: 2;
}

.item-quantity-select button {
  background-color: #fff;
  height: 35px;
  width: 90px;
}

.item-quantity-select button:nth-child(1) {
  border-top-left-radius: 10px;
  margin-right: 10px;
  border-bottom-left-radius: 10px;
}

.item-quantity-select button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: 10px;
}

.product-name-row-select {
  padding: 5px;
  font-size: 28px;
  width: 100%;
  height: 100px;
  word-wrap: break-word;
  overflow: hidden;
}

.item-quantity-select {
  display: flex;
}

.flex-container {
  display: flex;
  height: 70vh;
  justify-content: space-around;
  align-content: space-around;
  flex-direction: columns;
  padding: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.flex-direction-col {
  flex-direction: column;
}

.image-height-400-width-300 {
  height: 300px;
  width: 350px;
}

.big-keys .simple-keyboard .hg-row .hg-button {
  height: 85px !important;
  font-size: 70px;
}

.small-keys .simple-keyboard .hg-row .hg-button {
  height: 45px !important;
}

.padding-10 {
  padding: 10px;
}

.padding-right-20 {
  padding-right: 40px;
}
