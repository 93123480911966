.footer {
  display: flex;
  justify-content: center;
  height: 35px;
  bottom: -15px !important;
  position: fixed;
  margin: 24px auto !important;
  padding: 0px !important;
  width: 100%;
  z-index: 1;
}

.footer-v2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  // bottom: 0px !important;
  // position: fixed;
  padding: 0px !important;
  width: 100%;
  z-index: 1;
  background: #fff;
}

.footer-v2 span {
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 22px;
  color: #919eab;
  margin-right: 4px;
}

.footer-v2 img {
  vertical-align: middle;
  border-style: none;
  width: auto;
  height: 10px;
}

.footer-white-background {
  background: #fff;
  margin: 17px auto !important;
  height: 45px;
  padding: 10px !important;
}

.absolute-bottom-text {
  position: absolute;
  bottom: 0px;
  font-size: 18px;
}
